import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { actionAddFeedback } from 'store/feedback/actions';

import { ReactComponent as ArrowToRight } from 'assets/icons/ArrowToRight1.svg';

import { EMAIL_REGEX, TEXT_LENGTH_LIMIT, PHONE_REGEX } from 'constants';

import styles from './index.module.scss';

function ContactPage() {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: '',
      email: '',
      phoneNumber: '',
      subject: '',
      message: '',
    },

    validationSchema: Yup.object({
      name: Yup.string()
        .required(t('Tên là bắt buộc.'))
        .max(TEXT_LENGTH_LIMIT.VERY_SHORT, t(`Tên không được quá ${TEXT_LENGTH_LIMIT.VERY_SHORT} ký tự.`)),
      email: Yup.string()
        .trim()
        .required(t('Email là bắt buộc.'))
        .matches(EMAIL_REGEX, t('Định dạng email không hợp lệ.')),
      phoneNumber: Yup.string()
        .trim()
        .required(t('Số điện thoại là bắt buộc.'))
        .matches(PHONE_REGEX, t('Số điện thoại không hợp lệ.')),
      subject: Yup.string()
        .required(t('Chủ đề là bắt buộc.'))
        .max(TEXT_LENGTH_LIMIT.SHORT, t(`Chủ đề không được quá ${TEXT_LENGTH_LIMIT.SHORT} ký tự.`)),
      message: Yup.string()
        .required(t('Tin nhắn của bạn là bắt buộc.'))
        .max(TEXT_LENGTH_LIMIT.MEDIUM, t(`Tin nhắn của bạn không được quá ${TEXT_LENGTH_LIMIT.MEDIUM} ký tự.`)),
    }),

    onSubmit: (values) => {
      dispatch(actionAddFeedback(values));
    },
  });

  return (
    <div>
      <div className={styles.Container}>
        <div className={styles.InnerContainer}>
          <div className={styles.Subtitle}>{t('Gửi chúng tôi')}</div>

          <div className={styles.Title}>{t('NẾU BẠN CÓ THÊM CÂU HỎI')}</div>

          <form onSubmit={validation.handleSubmit}>
            <div className={styles.InputContainer}>
              <input
                type="name"
                placeholder={t('Nhập tên của bạn')}
                name="name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.name || ''}
                className={styles.Input}
              />

              {validation.touched.name && validation.errors.name
                ? <div className={styles.Errors}>{validation.errors.name}</div>
                : null}
            </div>

            <div className={styles.TwoInputContainer}>
              <div className={styles.InputContainer}>
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ''}
                  className={styles.Input}
                />

                {validation.touched.email && validation.errors.email
                  ? <div className={styles.Errors}>{validation.errors.email}</div>
                  : null}
              </div>

              <div className={styles.InputContainer}>
                <input
                  type="phoneNumber"
                  placeholder={t('Số điện thoại')}
                  name="phoneNumber"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.phoneNumber || ''}
                  className={styles.Input}
                />

                {validation.touched.phoneNumber && validation.errors.phoneNumber
                  ? <div className={styles.Errors}>{validation.errors.phoneNumber}</div>
                  : null}
              </div>
            </div>

            <div className={styles.InputContainer}>
              <input
                type="subject"
                placeholder={t('Chủ đề')}
                name="subject"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.subject || ''}
                className={styles.Input}
              />

              {validation.touched.subject && validation.errors.subject
                ? <div className={styles.Errors}>{validation.errors.subject}</div>
                : null}
            </div>

            <div className={styles.MessageContainer}>
              <textarea
                type="message"
                placeholder={t('Tin nhắn của bạn')}
                name="message"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.message || ''}
                className={styles.Input}
              />

              {validation.touched.message && validation.errors.message
                ? <div className={styles.Errors}>{validation.errors.message}</div>
                : null}
            </div>

            <button type="submit" className={styles.SubmitButton}>
              {t('GỬI ĐI')}

              <ArrowToRight />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
