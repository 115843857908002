import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Fb } from 'assets/icons/Fb.svg';
import { ReactComponent as Twitter } from 'assets/icons/Twitter.svg';
import { ReactComponent as Youtube } from 'assets/icons/Youtube.svg';
import { ReactComponent as Instagram } from 'assets/icons/Instagram.svg';
import { ReactComponent as Phone } from 'assets/icons/Phone.svg';
import { ReactComponent as Global } from 'assets/icons/Global.svg';
import { ReactComponent as Mail } from 'assets/icons/Mail.svg';
import { ReactComponent as ScheduleDeco } from 'assets/images/ScheduleDeco.svg';

import ContactBanner from 'assets/images/ContactBanner.png';

import styles from './index.module.scss';

function ContactTop() {
  const { t } = useTranslation();

  return (
    <div className={styles.ServicesTopContainer}>
      <div className={styles.InnerContainer}>
        <div className={styles.ServicesTop}>
          <div className={styles.ServicesInnerContainer}>
            <div className={styles.TextContainer}>
              <div className={styles.TextLeftContainer}>
                <div className={styles.Title}>{t('Liên lạc')}</div>

                <div className={styles.Info}>
                  <Phone />
                  +84 96 444 7153
                </div>
                <div className={styles.Info}>
                  <Global />
                  flf.vn
                </div>
                <div className={styles.Info}>
                  <Mail />
                  ruby.huynh@flf.vn
                </div>
              </div>

              <div className={styles.TextRightContainer}>
                <div className={styles.Title}>{t('Địa chỉ')}</div>

                <div className={styles.Info}>33 Tran Quy Khoach</div>
                <div className={styles.Info}>District 1</div>
                <div className={styles.Info}>Ho Chi Minh City</div>
              </div>
            </div>

            <div className={styles.IconContainer}>
              <Fb />
              <Twitter />
              <Youtube />
              <Instagram />
            </div>
          </div>

          <div className={styles.ImageContainer}>
            <img src={ContactBanner} alt="contact banner" />
          </div>
        </div>

        <ScheduleDeco className={styles.ScheduleDeco} />
      </div>
    </div>
  );
}

export default ContactTop;
