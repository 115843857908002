import React from 'react';

import PropTypes from 'prop-types';

import { TextReduceHelper } from 'utils';

// import { ReactComponent as LogoShield } from 'assets/icons/LogoShield.svg';

import styles from './index.module.scss';

function CaseCard1({ imgUrl, description }) {
  return (
    <div className={styles.Card}>
      <img className={styles.CardImg} src={imgUrl} alt={description} />

      <div className={styles.CardDescription}>
        <div className={styles.Title}>Case</div>

        <div>{TextReduceHelper({ text: description, maxLength: 75 })}</div>

        {/* <LogoShield className={styles.LogoShield} /> */}
      </div>
    </div>
  );
}

CaseCard1.propTypes = {
  imgUrl: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default CaseCard1;
