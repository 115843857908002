import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { actionGetAllCases } from 'store/actions';

import { ReactComponent as ArrowToRight } from 'assets/icons/ArrowToRight.svg';

// import SeaFood from 'assets/images/case1.jpg';
// import Farm from 'assets/images/case2.jpeg';
// import Audience from 'assets/images/case3.jpg';

import CaseCard from 'components/CaseCard/CaseCard';
import CaseCard1 from 'components/CaseCard1/CaseCard1';

import styles from './index.module.scss';

function Resources() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const data = useSelector((state) => state.Cases);

  const handleToCases = () => {
    navigate('/casestudy');
  };

  const selectedLanguage = localStorage.getItem('selectedLanguage');

  useEffect(() => {
    dispatch(actionGetAllCases());
  }, [selectedLanguage]);

  return (
    <div className={styles.Container}>
      <div className={styles.TitleContainer}>
        <div className={styles.Subtitle}>{t('Tài liệu')}</div>

        <div className={styles.Title}>{t('NGHIÊN CỨU')}</div>

        <div className={styles.Description}>
          {t('Chúng tôi là một đội ngũ cực kỳ linh hoạt, luôn cố gắng kết hợp các phương pháp tốt nhất của chúng tôi với cấu trúc và quy trình hiện có của khách hàng.')}
        </div>
      </div>

      <div className={styles.CardContainer}>
        {data.cases.slice(0, 3).map((c, index) => (
          <Link to={`/casestudy/${c.slug}`} key={c.slug}>
            {index === 0 ? (
              <CaseCard imgUrl={c.coverImage.url} description={c.title} />
            ) : (
              <CaseCard1 imgUrl={c.coverImage.url} description={c.title} />
            )}
          </Link>
        ))}
      </div>

      <button type="button" className={styles.Button} onClick={handleToCases}>
        {t('XEM TẤT CẢ')}

        <ArrowToRight />
      </button>
    </div>
  );
}

export default Resources;
