import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { motion } from 'framer-motion';

import { ReactComponent as ToLink } from 'assets/icons/ToLink.svg';

import LOCATIONS from 'constants';

import styles from './index.module.scss';

const contentFadeMotion = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

function CaseStudyDetailTop() {
  const location = useLocation();
  const path = location.pathname;
  const segments = path.split('/');
  const id = segments[2];
  const { t } = useTranslation();

  return (
    <motion.div
      key="hometop"
      initial="initial"
      animate="animate"
      exit="exit"
      variants={contentFadeMotion}
      transition={{ duration: 0.5 }}
      className={styles.ServicesTopContainer}
    >
      <div className={styles.InnerContainer}>
        <div className={styles.ServicesTop}>
          <div className={styles.Location}>
            <Link to={LOCATIONS.HOME}>{t('TRANG CHỦ')}</Link>
            {' '}
            <ToLink />

            <Link to="/casestudy">{t('NGHIÊN CỨU')}</Link>
            <span>
              <ToLink />
              {' '}
              {t('Chi tiết')}
            </span>
          </div>

          <div className={styles.Title}>
            CASE
            {' '}
            {id}
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default CaseStudyDetailTop;
