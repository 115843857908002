import React from 'react';
import { useTranslation } from 'react-i18next';

import { motion } from 'framer-motion';

import HomeBackground from 'assets/images/HomeBackground.png';
// import { ReactComponent as Shield } from 'assets/images/Shield.svg';
import { ReactComponent as ArrowToRight } from 'assets/icons/ArrowToRight.svg';

import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';

const shieldMotion = {
  initial: { top: '0rem', left: 'calc(80% - 12.5rem)' },
  animate: { left: '28.875rem', top: '16.5625rem' },
  exit: { top: '0rem', left: 'calc(80% - 6.25rem)' },
};

function HomeTop() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleToServices = () => {
    navigate('/services');
  };

  return (
    <div className={styles.HomeTop}>
      <div className={styles.InnerContainer}>
        <div className={styles.ImageContainer}>
          <img src={HomeBackground} alt="Home" className={styles.HomeBackground} />

          <motion.div
            key="shield"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={shieldMotion}
            transition={{ duration: 0.5 }}
            className={styles.Shield}
          >
            {/* <Shield /> */}
          </motion.div>
        </div>

        <div className={styles.HomeRight}>
          <div className={styles.Title}>
            {t('BẠN QUẢN LÝ DOANH NGHIỆP')}
          </div>

          <div className={styles.Subtitle}>{t('Chúng tôi bảo vệ')}</div>

          <button type="button" className={styles.ToServiceButton} onClick={handleToServices}>
            {t('Các dịch vụ')}
            <ArrowToRight />
          </button>
        </div>
      </div>
    </div>
  );
}

export default HomeTop;
