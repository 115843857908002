import React from 'react';

import AboutBanner from 'assets/images/AboutBanner.png';

import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';

function AboutUs() {
  const { t } = useTranslation();
  return (
    <div className={styles.Container}>
      <div className={styles.TextContainer}>
        <div className={styles.Subtitle}>{t('Tìm hiểu')}</div>

        <div className={styles.Title}>{t('VỀ CHÚNG TÔI')}</div>

        <div className={styles.Paragraph}>
          {t('First Legal Force (FLF) là tổ chức tiên phong hoạt động theo mô hình liên kết chặt chẽ với nhiều đơn vị hành nghề Luật sư chuyên nghiệp, song song với việc tập hợp các tổ chức chuyên gia về phát triển và quản trị kinh doanh trong cả nước. FLF ra đời nhằm cung cấp giải pháp, chia sẻ các gánh nặng và đồng hành cùng doanh nghiệp giúp họ “Phòng tránh - Giảm thiểu - Chuyển giao” các rủi ro pháp lý hiện tại hoặc tiềm ẩn trong suốt quá trình hoạt động kinh doanh của doanh nghiệp.')}
        </div>

        {/* <div className={styles.SecondParagraph}>
          In 2021,
          we has resolved 20 different cases of Blockchain
          courts and decided to run another branch called
          Legal3 where we can 100% focus on our mission to
          protect investors and providing startups with
          the tools to propel to new heights.
        </div> */}
      </div>

      <div className={styles.ImageContainer}>
        <img src={AboutBanner} alt="banner" className={styles.Image} />
      </div>
    </div>
  );
}

export default AboutUs;
