import { put, call, takeLeading } from 'redux-saga/effects';
import { request } from 'graphql-request';

import { addOneMonth } from 'utils';
import {
  GET_ALL_BLOGS,
  GET_LATEST_HIGHLIGHT_BLOG,
  GET_NEXT_BLOGS,
  GET_PREVIOUS_BLOGS,
  GET_RELATED_BLOGS,
  GET_ALL_BLOGS_IN_MONTH,
} from './actionTypes';

import {
  actionGetAllBlogsSuccess,
  actionGetAllBlogsFailed,

  actionGetLatestHighlightBlogSuccess,
  actionGetLatestHighlightBlogFailed,

  actionGetNextBlogsSuccess,
  actionGetNextBlogsFailed,

  actionGetPreviousBlogsSuccess,
  actionGetPreviousBlogsFailed,

  actionGetRelatedBlogsSuccess,
  actionGetRelatedBlogsFailed,

  actionGetAllBlogsInMonthSuccess,
  actionGetAllBlogsInMonthFailed,
} from './actions';

const ALL_BLOGS_QUERY = `
  query MyQuery($locales: [Locale!] = [vi]) {
    blogsConnection(where: { isHighlight: false }, orderBy: date_DESC, first: 7, locales: $locales) {
      edges {
        node {
          coverImage(locales: [vi]) {
            url
          }
          date
          excerpt
          slug
          title
          content {
            html
          }
          author {
            name
          }
          isHighlight
        }
      }
    }
  }
`;

const ALL_BLOGS_IN_MONTH_QUERY = `
  query MyQuery($startDate: Date!, $endDate: Date!, $locales: [Locale!] = [vi]) {
    blogsConnection(
      where: { isHighlight: false, date_gte: $startDate, date_lt: $endDate },
      orderBy: date_DESC,
      first: 5,
      locales: $locales
    ) {
      edges {
        node {
          coverImage(locales: [vi]) {
            url
          }
          date
          excerpt
          slug
          title
          content {
            html
          }
          author {
            name
          }
          isHighlight
        }
      }
    }
  }
`;

const HIGHLIGHT_QUERY = `
  query MyQuery($locales: [Locale!] = [vi]) {
    blogsConnection(where: { isHighlight: true }, orderBy: date_ASC, first: 1, locales: $locales) {
      edges {
        node {
          coverImage(locales: [vi]) {
            url
          }
          date
          excerpt
          slug
          title
          content {
            html
          }
          author {
            name
          }
          isHighlight
        }
      }
    }
  }
`;

const NEXT_BLOGS_QUERY = `
  query MyQuery($cursor: Date!, $locales: [Locale!] = [vi]) {
    blogsConnection(where: { isHighlight: false, date_gt: $cursor }, orderBy: date_DESC, first: 5, locales: $locales) {
      edges {
        node {
          coverImage(locales: [vi]) {
            url
          }
          date
          excerpt
          slug
          title
          content {
            html
          }
          author {
            name
          }
          isHighlight
        }
      }
    }
  }
`;

const PREVIOUS_BLOGS_QUERY = `
  query MyQuery($cursor: Date!, $locales: [Locale!] = [vi]) {
    blogsConnection(where: { isHighlight: false, date_lt: $cursor }, orderBy: date_DESC, first: 5, locales: $locales) {
      edges {
        node {
          coverImage(locales: [vi]) {
            url
          }
          date
          excerpt
          slug
          title
          content {
            html
          }
          author {
            name
          }
          isHighlight
        }
      }
    }
  }
`;

const RELATED_QUERY = `
query MyQuery($tags: [String!], $locales: [Locale!] = [vi]) {
  blogs(where: { tags_contains_some: $tags }, locales: $locales) {
    coverImage(locales: [vi]) {
      url
    }
    title
    slug
  }
}
`;

function* getAllBlogs() {
  try {
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    const locales = selectedLanguage ? [selectedLanguage] : ['vi'];

    const data = yield call(
      request,
      process.env.REACT_APP_GRAPH_ENDPOINT,
      ALL_BLOGS_QUERY,
      { locales },
    );

    yield put(actionGetAllBlogsSuccess(data));
  } catch (error) {
    yield put(actionGetAllBlogsFailed());
  }
}

function* getAllBlogsInMonth(action) {
  const { selectedDate } = action.payload;
  const selectedLanguage = localStorage.getItem('selectedLanguage');
  const locales = selectedLanguage ? [selectedLanguage] : ['vi'];

  try {
    const startDate = selectedDate;
    const endDate = addOneMonth(selectedDate);

    const variables = {
      startDate,
      endDate,
      locales,
    };

    const data = yield call(
      request,
      process.env.REACT_APP_GRAPH_ENDPOINT,
      ALL_BLOGS_IN_MONTH_QUERY,
      variables,
    );

    yield put(actionGetAllBlogsInMonthSuccess(data));
  } catch (error) {
    yield put(actionGetAllBlogsInMonthFailed());
  }
}

function* getLatestHighlightBlog() {
  try {
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    const locales = selectedLanguage ? [selectedLanguage] : ['vi'];

    const data = yield call(
      request,
      process.env.REACT_APP_GRAPH_ENDPOINT,
      HIGHLIGHT_QUERY,
      { locales },
    );

    yield put(actionGetLatestHighlightBlogSuccess(data));
  } catch (error) {
    yield put(actionGetLatestHighlightBlogFailed());
  }
}

function* getNextBlogs(action) {
  try {
    const { cursor } = action.payload;
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    const locales = selectedLanguage ? [selectedLanguage] : ['vi'];

    const data = yield call(
      request,
      process.env.REACT_APP_GRAPH_ENDPOINT,
      NEXT_BLOGS_QUERY,
      { cursor, locales },
    );

    yield put(actionGetNextBlogsSuccess(data));
  } catch (error) {
    yield put(actionGetNextBlogsFailed());
  }
}

function* getPreviousBlogs(action) {
  try {
    const { cursor } = action.payload;
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    const locales = selectedLanguage ? [selectedLanguage] : ['vi'];

    const data = yield call(
      request,
      process.env.REACT_APP_GRAPH_ENDPOINT,
      PREVIOUS_BLOGS_QUERY,
      { cursor, locales },
    );

    yield put(actionGetPreviousBlogsSuccess(data));
  } catch (error) {
    yield put(actionGetPreviousBlogsFailed());
  }
}

function* getRelatedBlogs(action) {
  try {
    const { tags } = action.payload;
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    const locales = selectedLanguage ? [selectedLanguage] : ['vi'];

    const variables = { tags, locales };
    const response = yield call(
      request,
      process.env.REACT_APP_GRAPH_ENDPOINT,

      RELATED_QUERY,

      variables,
    );

    yield put(actionGetRelatedBlogsSuccess(response));
  } catch (error) {
    yield put(actionGetRelatedBlogsFailed());
  }
}

export default function* BlogSaga() {
  yield takeLeading(GET_ALL_BLOGS, getAllBlogs);
  yield takeLeading(GET_LATEST_HIGHLIGHT_BLOG, getLatestHighlightBlog);
  yield takeLeading(GET_NEXT_BLOGS, getNextBlogs);
  yield takeLeading(GET_PREVIOUS_BLOGS, getPreviousBlogs);
  yield takeLeading(GET_RELATED_BLOGS, getRelatedBlogs);
  yield takeLeading(GET_ALL_BLOGS_IN_MONTH, getAllBlogsInMonth);
}
