import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Ellipse } from 'assets/icons/Ellipse.svg';
import { ReactComponent as ServicesDeco } from 'assets/images/ServicesDeco.svg';

import styles from './index.module.scss';

function OtherServies() {
  const { id } = useParams();
  const { t } = useTranslation();

  return (
    <div className={styles.Container}>
      <div>
        <div className={styles.Subtitle}>Other Services</div>

        <div className={styles.List}>
          {id !== '1' && (
          <div className={styles.Item}>
            <Link to="/services/1">
              <span>01</span>
              <div>{t('Thành lập doanh nghiệp 100% vốn nước ngoài')}</div>
            </Link>

            <div>
              <Ellipse />
            </div>

            <div className={styles.Divider} />
          </div>
          )}

          {id !== '2' && (
          <div className={styles.Item}>
            <Link to="/services/2">
              <span>02</span>
              <div>{t('Đăng ký quyền tác giả')}</div>
            </Link>

            <div>
              <Ellipse />
            </div>

            <div className={styles.Divider} />
          </div>
          )}

          {id !== '3' && (
          <div className={styles.Item}>
            <Link to="/services/3">
              <span>03</span>
              <div>{t('Cấp giấy phép lao động cho người nước ngoài')}</div>
            </Link>

            <div>
              <Ellipse />
            </div>

            <div className={styles.Divider} />
          </div>
          )}

          {id !== '4' && (
          <div className={styles.Item}>
            <Link to="/services/4">
              <span>04</span>
              <div>{t('Tư vấn về Technical & Blockchain & Crypto')}</div>
            </Link>

            <div>
              <Ellipse />
            </div>

            <div className={styles.Divider} />
          </div>
          )}

          {id !== '5' && (
          <div className={styles.Item}>
            <Link to="/services/5">
              <span>05</span>
              <div>{t('Tư vấn mua bán và sáp nhập doanh nghiệp')}</div>
            </Link>

            <div>
              <Ellipse />
            </div>

            <div className={styles.Divider} />
          </div>
          )}

          {id !== '6' && (
          <div className={styles.Item}>
            <Link to="/services/6">
              <span>06</span>
              <div>{t('Thực hiện thủ tục giấy phép kinh doanh')}</div>
            </Link>

            <div>
              <Ellipse />
            </div>

            <div className={styles.Divider} />
          </div>
          )}
        </div>
      </div>

      <ServicesDeco className={styles.ServicesDeco} />
    </div>
  );
}

export default OtherServies;
