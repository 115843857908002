import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import CaseCard1 from 'components/CaseCard1/CaseCard1';

import { useDispatch, useSelector } from 'react-redux';
import { actionGetRelatedCases } from 'store/actions';

import { filterBlogsByLanguage } from 'utils';

import styles from './index.module.scss';

function RelatedCases({ tags }) {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.Cases);

  const selectedLanguage = localStorage.getItem('selectedLanguage');

  const filteredCases = filterBlogsByLanguage(data.relatedCases, selectedLanguage);

  useEffect(() => {
    dispatch(actionGetRelatedCases({ tags }));
  }, [tags]);

  return (
    <div className={styles.CardsContainer}>
      <div className={styles.Title}>Related Cases</div>

      <div className={styles.InnerContainer}>
        {filteredCases.map((c) => (
          <Link to={`/casestudy/${c.slug}`} key={c.slug}>

            <CaseCard1 imgUrl={c.coverImage.url} description={c.title} />

          </Link>
        ))}
      </div>
    </div>
  );
}

RelatedCases.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
};

RelatedCases.defaultProps = {
  tags: [],
};

export default RelatedCases;
