import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowToRight } from 'assets/icons/ArrowToRight.svg';
import { ReactComponent as ScheduleDeco } from 'assets/images/ScheduleDeco.svg';

import styles from './index.module.scss';

function ScheduleContact() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleToContact = () => {
    navigate('/contact');
  };

  return (
    <div className={styles.Container}>
      <div className={styles.SubTitle}>
        {t('Đặt lịch tư vấn')}
      </div>

      <div className={styles.Title}>
        {t('CHÚNG TÔI Ở ĐÂY ĐỂ GIÚP BẠN')}
      </div>

      <button type="button" className={styles.Button} onClick={handleToContact}>
        <span>
          <div>
            {t('LIÊN HỆ')}
          </div>

          <ArrowToRight />
        </span>
      </button>

      <ScheduleDeco className={styles.ScheduleDeco1} />
      <ScheduleDeco className={styles.ScheduleDeco2} />
    </div>
  );
}

export default ScheduleContact;
