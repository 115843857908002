import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Ellipse } from 'assets/icons/Ellipse.svg';
import { ReactComponent as ArrowToRight1 } from 'assets/icons/ArrowToRight1.svg';
// import { ReactComponent as Shield } from 'assets/icons/Shield.svg';
// import { ReactComponent as HomeConnect } from 'assets/images/HomeConnect.svg';

import styles from './index.module.scss';

function OurServices() {
  const { t } = useTranslation();
  return (
    <div className={styles.Container}>
      <div>
        <div className={styles.Subtitle}>{t('Các dịch vụ')}</div>

        <div className={styles.Title}>
          FIRST LEGAL FORCE
          <br />
          {t('CUNG CẤP CÁC DỊCH VỤ:')}
        </div>

        <div className={styles.List}>
          <div className={`${styles.Active} ${styles.Item}`}>
            <Link to="/services/1">
              <span>01</span>
              <div>{t('Thành lập doanh nghiệp 100% vốn nước ngoài')}</div>
              <ArrowToRight1 className={styles.ArrowToRight} />
            </Link>

            <div>
              {/* <Shield /> */}
            </div>

            <div className={styles.Divider} />
          </div>

          <div className={styles.Item}>
            <Link to="/services/2">
              <span>02</span>
              <div>{t('Đăng ký quyền tác giả')}</div>
            </Link>

            <div>
              <Ellipse />
            </div>

            <div className={styles.Divider} />
          </div>

          <div className={styles.Item}>
            <Link to="/services/3">
              <span>03</span>
              <div>{t('Cấp giấy phép lao động cho người nước ngoài')}</div>
            </Link>

            <div>
              <Ellipse />
            </div>

            <div className={styles.Divider} />
          </div>

          <div className={styles.Item}>
            <Link to="/services/4">
              <span>04</span>
              <div>{t('Tư vấn về Technical & Blockchain & Crypto')}</div>
            </Link>

            <div>
              <Ellipse />
            </div>

            <div className={styles.Divider} />
          </div>

          <div className={styles.Item}>
            <Link to="/services/5">
              <span>05</span>
              <div>{t('Tư vấn mua bán và sáp nhập doanh nghiệp')}</div>
            </Link>

            <div>
              <Ellipse />
            </div>

            <div className={styles.Divider} />
          </div>

          <div className={styles.Item}>
            <Link to="/services/6">
              <span>06</span>
              <div>{t('Thực hiện thủ tục giấy phép kinh doanh')}</div>
            </Link>

            <div>
              <Ellipse />
            </div>

            <div className={styles.Divider} />
          </div>
        </div>
      </div>

      {/* <HomeConnect className={styles.HomeConnect} /> */}
    </div>
  );
}

export default OurServices;
