import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { v4 as uuidv4 } from 'uuid';

import {
  actionGetAllBlogs,
  actionGetAllBlogsInMonth,
  actionGetLatestHighlightBlog,
  actionGetNextBlogs,
  actionGetPreviousBlogs,
} from 'store/actions';

import ScheduleContact from 'components/ScheduleContact/ScheduleContact';

import { ReactComponent as ArrowToRight } from 'assets/icons/ArrowToRight1.svg';
import { ReactComponent as ToLink } from 'assets/icons/ToLink.svg';

import SmallCard from 'components/SmallCard/SmallCard';
import Card from './Card/Card';

import styles from './index.module.scss';

const generateMonthItems = (handleDateChange) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  return Array.from({ length: 12 }).map((_, index) => {
    const monthIndex = (currentMonth - index + 12) % 12;
    const year = currentYear + Math.floor((currentMonth - index) / 12);
    const date = new Date(year, monthIndex);
    const formattedMonth = `${date.toLocaleString('en-US', { month: 'long' })} ${year}`;
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;

    return (
      <button
        type="button"
        key={uuidv4()}
        className={styles.ArchiveItem}
        onClick={() => handleDateChange(formattedDate)}
      >
        {formattedMonth}
      </button>
    );
  });
};

function BlogPage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const data = useSelector((state) => state.Blogs);

  const selectedLanguage = localStorage.getItem('selectedLanguage');

  const handleDateChange = (selectedDate) => {
    dispatch(actionGetAllBlogsInMonth({ selectedDate }));
  };

  const months = generateMonthItems(handleDateChange);

  const calculateTimeToRead = (htmlContent) => {
    const wordsPerMinute = 150;

    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const text = doc.documentElement.textContent || '';

    const words = text.trim().split(/\s+/);
    const wordCount = words.length;

    const readingTime = Math.ceil(wordCount / wordsPerMinute);

    return readingTime;
  };

  const formatDate = (dateString) => {
    const options = { month: 'long', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const fetchOlderPosts = async () => {
    dispatch(actionGetPreviousBlogs({ cursor: data.blogs[data.blogs.length - 1].date }));
  };

  const fetchNewerPosts = async () => {
    dispatch(actionGetNextBlogs({ cursor: data.blogs[0].date }));
  };

  useEffect(() => {
    dispatch(actionGetAllBlogs());
    dispatch(actionGetLatestHighlightBlog());
  }, [selectedLanguage]);

  return (
    <div>
      <div className={styles.Container}>
        <div className={styles.InnerContainer}>
          <div className={styles.LeftContainer}>
            {data.highlightedBlog && (
            <div className={styles.HighlightContainer}>
              <div className={styles.Highlight}>{t('NỔI BẬT')}</div>
              <Link to={data.highlightedBlog.slug}>
                <div className={styles.Title}>{data.highlightedBlog.title}</div>
              </Link>
              <div className={styles.Subtitle}>
                {formatDate(data.highlightedBlog.date)}
                , by
                {' '}
                {data.highlightedBlog.author?.name ? (
                  <span>{data.highlightedBlog.author.name}</span>
                ) : (
                  <span>Anonymous</span>
                )}
              </div>
              <img
                src={data.highlightedBlog.coverImage.url}
                className={styles.Image}
                alt={data.highlightedBlog.slug}
              />
              <div className={styles.Description}>{data.highlightedBlog.excerpt}</div>
              <Link to={data.highlightedBlog.slug}>
                <div className={styles.TimeToRead}>
                  {calculateTimeToRead(data.highlightedBlog.content.html)}
                  {' '}
                  {t('phút đọc')}
                  <ArrowToRight />
                </div>
              </Link>
            </div>
            )}

            <div className={styles.CardContainer}>
              <div className={styles.Divider1} />

              {data.loading ? (
                <div className={styles.Loading}>
                  <div className={styles.LoadingLine} />
                </div>
              ) : (
                data.blogs.map((post) => (
                  <Link to={post.slug} key={post.slug}>
                    <Card
                      image={post.coverImage.url}
                      title={post.title}
                      description={post.excerpt}
                      timeToRead={calculateTimeToRead(post.content.html)}
                    />
                  </Link>
                ))
              )}

              <div className={styles.Divider} />
            </div>

            <div className={styles.SelectContainer}>
              <button type="button" className={styles.SelectItem} onClick={fetchOlderPosts}>
                <ToLink />

                {t('Cũ hơn')}
              </button>

              <button type="button" className={styles.SelectItem1} onClick={fetchNewerPosts}>
                {t('Mới hơn')}

                <ToLink />
              </button>
            </div>
          </div>

          <div className={styles.RightContainer}>
            <div className={styles.TopCardContainer}>
              {data.randomBlogs.length ? (
                data.randomBlogs.map((post) => (
                  <Link to={post.slug} key={post.slug} className={styles.ToBlog}>
                    <SmallCard
                      image={post.coverImage.url}
                      title={post.title}
                    />
                  </Link>
                ))
              ) : (
                <div className={styles.Card} />
              )}
            </div>

            <div className={styles.ArchiveContainer}>
              <div className={styles.Archive}>{t('LƯU TRỮ')}</div>

              <div>
                {months}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ScheduleContact />
    </div>
  );
}

export default BlogPage;
