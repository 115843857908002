import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Service1 } from 'assets/icons/Service1.svg';
import { ReactComponent as Service2 } from 'assets/icons/Service2.svg';
import { ReactComponent as Service3 } from 'assets/icons/Service3.svg';
import { ReactComponent as Service4 } from 'assets/icons/Service4.svg';
import { ReactComponent as Tick } from 'assets/icons/Tick.svg';

import RelatedCases from 'components/RelatedCases/RelatedCases';
import OtherServies from './OtherServices/OtherServies';

import styles from './index.module.scss';

function ServiceDetailsPage() {
  const { id } = useParams();
  const { t } = useTranslation();
  return (
    <div className={styles.Container}>
      {id === '1' && (
      <div className={styles.ContentContainer}>
        <div className={styles.ShieldContainer}>
          <Service1 />
        </div>

        <div className={styles.TextContainer}>
          <div className={styles.Title}>{t('THÀNH LẬP DOANH NGHIỆP 100% VỐN NƯỚC NGOÀI')}</div>

          <div>
            {t('Công ty 100% vốn nước ngoài là một pháp nhân Việt Nam nhưng 100% vốn góp do nhà đầu tư góp vốn để kinh doanh, hoạt động tại Việt Nam. Công ty 100% vốn nước ngoài được thành lập và hoạt động theo quy định của Luật Đầu tư, Luật Doanh nghiệp và các văn bản pháp lý liên quan.')}
          </div>

          <br />

          <div>
            {t('Trước khi thực hiện thủ tục thành lập doanh nghiệp có vốn nước ngoài tại Việt Nam, nhà đầu tư nước ngoài cần tham khảo danh sách những ngành nghề được phép đầu tư 100% vốn nước ngoài.')}
          </div>

          <div className={styles.Item}>
            {t('Quy trình thành lập doanh nghiệp 100% vốn nước ngoài được thực hiện theo các bước như sau:')}
          </div>

          <div>
            <div className={styles.NumContainer}>
              <div className={styles.NumHead}>1</div>

              <div>
                {t('Đăng ký chủ trương đầu tư tại cơ quan có thẩm quyền để thành lập công ty 100% vốn nước ngoài')}
              </div>
            </div>

            <div className={styles.NumContainer}>
              <div className={styles.NumHead}>2</div>

              {t('Xin cấp Giấy chứng nhận đăng ký đầu tư cho công ty 100% vốn nước ngoài')}
            </div>

            <div className={styles.NumContainer}>
              <div className={styles.NumHead}>3</div>

              <div>
                {t('Thành lập doanh nghiệp cấp giấy chứng nhận đăng ký doanh nghiệp cho công ty 100% vốn nước ngoài')}

                <ul>
                  <li>
                    {t('Sau khi có giấy chứng nhận đăng ký đầu tư, nhà đầu tư cần chuẩn bị hồ sơ để thành lập doanh nghiệp.')}
                  </li>
                </ul>
              </div>
            </div>

            <div className={styles.NumContainer}>
              <div className={styles.NumHead}>4</div>

              {t('Đăng bố cáo thành lập công ty 100% vốn nước ngoài')}
            </div>

            <div className={styles.NumContainer}>
              <div className={styles.NumHead}>5</div>

              {t('Khắc dấu của công ty 100% vốn nước ngoài')}
            </div>
          </div>
        </div>
      </div>
      )}

      {id === '2' && (
      <div className={styles.ContentContainer}>
        <div>
          <Service2 />
        </div>

        <div className={styles.TextContainer}>
          <div className={styles.Title}>{t('ĐĂNG KÝ QUYỀN TÁC GIẢ')}</div>

          <div className={styles.Item}>
            {t('Nếu chủ thể đăng ký là chủ sở hữu đồng thời là tác giả/đồng tác giả')}
          </div>

          <div>
            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('03 bản mẫu tác phẩm cần đăng ký; tác phẩm đã được công bố hay chưa; thời gian công bố.')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('02 Bản sao y công chứng còn thời hạn CMND/CCCD/Hộ chiếu.')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Tên đầy đủ, bút danh (nếu có), địa chỉ, số điện thoại, fax của tác giả/các đồng tác giả.')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Giấy cam đoan của tác giả độc lập sáng tạo tác phẩm, không vi phạm bản quyền của ai - 1 bản (theo mẫu).')}
            </div>
          </div>

          <div className={styles.Item}>
            {t('Nếu chủ thể đăng ký là chủ sở hữu không đồng thời là tác giả')}
          </div>

          <div>
            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('03 bản mẫu tác phẩm gốc.')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('02 Bản sao y công chứng còn thời hạn CMND/CCCD/Hộ chiếu của tác giả/các tác giả tác phẩm.')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Giấy chuyển nhượng quyền sở hữu tác phẩm của tác giả/các tác giả cho chủ sở hữu tác phẩm (tổ chức, công ty) (1 bản).')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Hợp đồng giao việc giữa chủ sở hữu tác phẩm(pháp nhân) và tác giả (cá nhân), trong trường hợp tác giả thực hiện tác phẩm theo nhiệm vụ được giao và hưởng lương hoặc trong trường hợp chủ sở hữu tác phẩm bỏ toàn bộ chi phí để tác giả thực hiện tác phẩm.')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Bản sao giấy chứng nhận đăng ký kinh doanh hoặc quyết định thành lập có công chứng.')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Giấy cam đoan của tác giả/các tác giả độc lập sáng tạo tác phẩm, không vi phạm bản quyền của ai (theo mẫu).')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Các thông tin khác: bút danh của tác giả; địa chỉ; số điện thoại và fax của tác giả, chủ sở hữu tác phẩm.')}
            </div>
          </div>

          <div className={styles.Item}>
            {t('Lệ phí dịch vụ đăng ký bản quyền tác giả : (tùy theo loại hình tác phẩm, độ dài, độ phức tạp của tác phẩm)')}
          </div>

          <div>
            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Các quy trình soạn thảo, nộp hồ sơ và nhận giấy phép do FLF thực hiện. Đến với FLF quý khách “KHÔNG ĐI LẠI, KHÔNG CHỜ ĐỢI“')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Quý khách muốn biết thêm chi tiết vui lòng liên hệ với chúng tôi.')}
            </div>
          </div>
        </div>
      </div>
      )}

      {id === '3' && (
      <div className={styles.ContentContainer}>
        <div>
          <Service3 />
        </div>

        <div className={styles.TextContainer}>
          <div className={styles.Title}>{t('CẤP GIẤY PHÉP LAO ĐỘNG CHO NGƯỜI NƯỚC NGOÀI')}</div>

          <div>
            {t('Với nhu cầu tuyển dụng người lao động nước ngoài vào Việt Nam làm việc ngày càng nhiều, pháp luật Việt Nam cho phép doanh nghiệp, cơ quan, tổ chức, cá nhân, nhà thầu tuyển dụng người lao động nước ngoài vào làm vị trí công việc quản lý, điều hành, chuyên gia và lao động kỹ thuật mà người lao động Việt Nam chưa đáp ứng được theo nhu cầu sản xuất, kinh doanh. Để người lao động nước ngoài làm việc hợp pháp tại Việt Nam thì người lao động nước ngoài cần có giấy phép lao động.')}
          </div>

          <div className={styles.Item}>
            {t('Nếu chủ thể đăng ký là chủ sở hữu không đồng thời là tác giả')}
          </div>

          <div>
            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>

              {t('Đủ 18 tuổi trở lên và có năng lực hành vi dân sự đầy đủ;')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Có trình độ chuyên môn, kỹ thuật, tay nghề, kinh nghiệm làm việc; có đủ sức khỏe theo quy định của Bộ trưởng Bộ Y tế;')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Không phải là người đang trong thời gian chấp hành hình phạt hoặc chưa được xóa án tích hoặc đang trong thời gian bị truy cứu trách nhiệm hình sự theo quy định của pháp luật nước ngoài hoặc pháp luật Việt Nam;')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Có giấy phép lao động do cơ quan nhà nước có thẩm quyền của Việt Nam cấp, trừ trường hợp người lao động nước ngoài làm việc tại Việt Nam không thuộc diện cấp giấy phép lao động theo Điều 154 Bộ luật Lao động năm 2019.')}
            </div>
          </div>

          <div>
            {t('Với nhu cầu tuyển dụng người lao động nước ngoài vào Việt Nam làm việc ngày càng nhiều, pháp luật Việt Nam cho phép doanh nghiệp, cơ quan, tổ chức, cá nhân, nhà thầu tuyển dụng người lao động nước ngoài vào làm vị trí công việc quản lý, điều hành, chuyên gia và lao động kỹ thuật mà người lao động Việt Nam chưa đáp ứng được theo nhu cầu sản xuất, kinh doanh. Để người lao động nước ngoài làm việc hợp pháp tại Việt Nam thì người lao động nước ngoài cần có giấy phép lao động.')}
          </div>

          <div className={styles.Item}>
            {t('Đối tượng người nước ngoài nào được cấp giấy phép lao động tại Việt Nam')}
          </div>

          <div>
            {t('Việc cấp giấy phép lao động nước ngoài được áp dụng cho người nước ngoài làm việc tại Việt Nam, cụ thể gồm các đối tượng như sau:')}
          </div>

          <br />

          <div>
            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>

              {t('Thực hiện hợp đồng lao động;')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Di chuyển trong nội bộ doanh nghiệp;')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Nhà đầu tư góp vốn vào công ty Việt Nam, thành lập công ty Việt Nam nhưng góp vốn dưới 3 tỷ đồng Việt Nam;')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Thực hiện các loại hợp đồng hoặc thỏa thuận về kinh tế, thương mại, tài chính, ngân hàng, bảo hiểm, khoa học kỹ thuật, văn hóa, thể thao, giáo dục, giáo dục nghề nghiệp và y tế;')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Nhà cung cấp dịch vụ theo hợp đồng;')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Chào bán dịch vụ;')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Làm việc cho tổ chức phi chính phủ nước ngoài, tổ chức quốc tế tại Việt Nam được phép hoạt động theo quy định của pháp luật Việt Nam;')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Tình nguyện viên;')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Người chịu trách nhiệm thành lập hiện diện thương mại;')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Nhà quản lý, giám đốc điều hành, chuyên gia, lao động kỹ thuật;')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Tham gia thực hiện các gói thầu, dự án tại Việt Nam;')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Thân nhân thành viên cơ quan đại diện nước ngoài tại Việt Nam được phép làm việc tại Việt Nam theo quy định tại điều ước quốc tế mà nước ngoài là thành viên;')}
            </div>
          </div>

          <div className={styles.Item}>
            {t('Danh mục hồ sơ cấp giấy phép lao động và lưu ý về hồ sơ cấp giấy phép lao động')}
          </div>

          <div>
            {t('Hồ sơ cấp giấy phép lao động cần chuẩn bị bao gồm:')}
          </div>
          <br />

          <div>
            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>

              <div>
                {t('Giấy chứng nhận sức khỏe hoặc giấy khám sức khỏe do cơ quan, tổ chức y tế có thẩm quyền của nước ngoài hoặc của Việt Nam cấp có giá trị trong thời hạn 12 tháng, kể từ ngày ký kết luận sức khỏe đến ngày nộp hồ sơ hoặc giấy chứng nhận có đủ sức khỏe theo quy định của Bộ trưởng Bộ Y tế. Danh mục các bệnh viện đủ điều kiện khám sức khỏe xin cấp giấy phép lao động xin vui lòng tham khảo tại bài viết:')}
                {' '}
                <a href="https://luatvietan.vn/cac-benh-vien-kham-suc-khoe-cap-giay-phep-lao-dong.html">
                  https://luatvietan.vn/cac-benh-vien-kham-suc-khoe-cap-giay-phep-lao-dong.html
                </a>
              </div>
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Phiếu lý lịch tư pháp hoặc văn bản xác nhận người lao động nước ngoài không phải là người đang trong thời gian chấp hành hình phạt hoặc chưa được xóa án tích hoặc đang trong thời gian bị truy cứu trách nhiệm hình sự của nước ngoài hoặc của Việt Nam cấp.')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Văn bản, giấy tờ chứng minh là nhà quản lý, giám đốc điều hành, chuyên gia, lao động kỹ thuật')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Ảnh nộp cùng hồ sơ: 05 ảnh màu (kích thước 4 cm x 6 cm, phông nền trắng, mặt nhìn thẳng, đầu để trần, không đeo kính màu), ảnh chụp không quá 06 tháng tính đến ngày nộp hồ sơ.')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Bản sao giấy tờ cá nhân: Bản sao có chứng thực hộ chiếu còn giá trị theo quy định của pháp luật. Bao gồm tất cả các trang của hộ chiếu.')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Hợp đồng hoặc thỏa thuận ký kết giữa đối tác phía Việt Nam và phía nước ngoài,')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Văn bản của cơ quan, tổ chức cử người lao động nước ngoài đến làm việc.')}
            </div>
          </div>

          <div className={styles.Item}>
            {t('Quy trình cấp giấy phép lao động cho người lao động nước ngoài tại Việt Nam')}
          </div>

          <div>
            <div className={styles.NumContainer}>
              <div className={styles.NumHead}>1</div>
              {t('Xin chấp thuận nhu cầu sử dụng lao động là người nước ngoài')}
            </div>

            <div>
              {t('Trước ít nhất 30 ngày kể từ ngày dự kiến sử dụng người lao động nước ngoài, người sử dụng lao động (trừ nhà thầu) gửi báo cáo giải trình về nhu cầu sử dụng người lao động nước ngoài đến Bộ Lao động – Thương binh và Xã hội hoặc Chủ tịch Ủy ban nhân dân cấp tỉnh.')}

              <ul>
                <li>
                  {t('Trong vòng 10 ngày kể từ ngày nhận được tờ khai và báo cáo giải trình nhu cầu sử dụng lao động nước ngoài hợp lệ, cơ quan chấp thuận sẽ gửi kết quả qua thư điện tử của người sử dụng lao động. Nếu hồ sơ nộp chưa hợp lệ, cơ quan chấp thuận sẽ ra thông báo chỉnh sửa.')}
                </li>

                <li>
                  {t('Hồ sơ bao gồm: Mẫu báo cáo giải trình nhu cầu sử dụng lao động nước ngoài (theo mẫu số 01 Phụ lục 01 Nghị định số 152/2020/NĐ-CP) kèm bản sao đăng ký doanh nghiệp.')}
                </li>
              </ul>
            </div>

            <div className={styles.NumContainer}>
              <div className={styles.NumHead}>2</div>

              {t('Nộp hồ sơ cấp giấy phép lao động')}
            </div>

            <div>
              {t('Trước ít nhất 15 ngày làm việc, kể từ ngày người lao động nước ngoài dự kiến bắt đầu làm việc cho người sử dụng lao động thì người sử dụng lao động phải khai thông tin vào tờ khai và nộp hồ sơ đề nghị cấp giấy phép lao động theo quy định đến cơ quan cấp giấy phép lao động qua cổng thông tin điện tử.')}

              <ul>
                <li>
                  {t('Số lượng hồ sơ: 01 bộ')}
                </li>

                <li>
                  {t('Nộp hồ sơ tại: Nộp trực tiếp tại Bộ phận 1 cửa của cơ quan chấp thuận hoặc hệ thống cổng thông tin điện tử http://dvc.vieclamvietnam.gov.vn')}
                </li>

                <li>
                  {t('Thời gian giải quyết: 05 ngày làm việc')}
                </li>
              </ul>
            </div>

            <div className={styles.NumContainer}>
              <div className={styles.NumHead}>3</div>

              <div>
                {t('Ký kết hợp đồng lao động và báo cáo tình hình sử dụng lao động nước ngoài với Sở Lao động – Thương binh và Xã hội hoặc Ban quản lý các khu công nghiệp và chế xuất của tỉnh')}
              </div>
            </div>

            <div>
              {t('Sau khi người nước ngoài được cấp giấy phép lao động thì Doanh nghiệp (Người sử dụng lao động) phải thực hiện các thủ tục như sau:')}

              <ul>
                <li>
                  {t('Ký kết hợp đồng lao động với người được cấp giấy phép lao động;')}
                </li>

                <li>
                  {t('Đóng bảo hiểm y tế, bảo hiểm xã hội cho người lao động nước ngoài. Kể từ ngày 01/01/2018 người lao động nước ngoài nếu đã được cấp giấy phép lao động, chứng chỉ hành nghề hoặc giấy phép hành nghề bởi cơ quan có thẩm quyền của Việt Nam cũng là đối tượng bắt buộc tham gia bảo hiểm xã hội theo quy định của Luật Bảo hiểm xã hội 2014.')}
                </li>

                <li>
                  {t('Báo cáo tình hình sử dụng lao động nước ngoài: Hiện tại người sử dụng lao động (trừ nhà thầu) không phải thực hiện thực hiện chế độ báo cáo định kỳ. Thay vào đó, người sử dụng lao động sẽ báo cáo khi có yêu cầu của cơ quan nhà nước có thẩm quyền.')}
                </li>
              </ul>
            </div>

            <div className={styles.NumContainer}>
              <div className={styles.NumHead}>4</div>

              {t('Cấp thẻ tạm trú theo thời hạn của giấy phép lao động cho người nước ngoài')}
            </div>

            <div>
              {t('Sau khi Người lao động nước ngoài được cấp Giấy phép lao động tại Việt Nam, thì việc tiếp theo là xin cấp Thẻ tạm trú theo thời hạn của Giấy phép lao động. Tại Việt Nam thời hạn của Giấy phép lao động tối đa là 02 năm vì vậy thời gian tối đa của thẻ tạm trú cũng là 02 năm theo giấy phép lao động.')}

              <br />
              <br />

              <i>{t('Hồ sơ xin cấp thẻ tạm trú')}</i>

              <ul>
                <li>
                  {t('Văn bản đề nghị của cơ quan, tổ chức, cá nhân làm thủ tục mời, bảo lãnh (thường là người sử dụng lao động)')}
                </li>

                <li>
                  {t('Tờ khai đề nghị cấp thẻ tạm trú có dán ảnh: Mẫu NA8 được ban hành kèm theo Thông tư số 04/2015/TT-BCA Quy định mẫu giấy tờ liên quan đến việc Nhập cảnh, xuất cảnh, cư trú của người nước ngoài tại Việt Nam. Tờ khai này được người đề nghị cấp thể tạm trú ký, ghi rõ họ tên. Cơ quan, tổ chức bảo lãnh đóng dấu giáp lai ảnh và tờ khai và đóng dấu treo ở bên còn lại;')}
                </li>

                <li>
                  {t('Hộ chiếu của người đề nghị cấp thẻ tạm trú;')}
                </li>

                <li>
                  {t('Giấy phép lao động của người đề nghị cấp thẻ tạm trú;')}
                </li>

                <li>
                  {t('03 ảnh mầu (kích thước 2cm x 3cm, phông nền trắng, mặt nhìn thẳng, đầu để trần, không đeo kính màu), ảnh chụp không quá 06 tháng tính đến ngày nộp hồ sơ;')}
                </li>

                <li>
                  {t('Giấy xác nhận tạm trú do Công an xã/phường nơi người nước ngoài tạm trú xác nhận;')}
                </li>

                <li>
                  {t('Tài liệu chứng minh tư cách pháp nhân của đơn vị bảo lãnh.')}
                </li>
              </ul>
            </div>

            <div>
              <i>{t('Lưu ý về sử dụng giấy phép lao động, thẻ tạm trú')}</i>
              <br />
              <br />

              {t('Trường hợp người lao động chấm dứt hợp đồng lao động với doanh nghiệp, tổ chức cần thực hiện thu hồi lại giấy phép lao động và thẻ tạm trú đã cấp cho người nước ngoài với tư cách là người lao động tại doanh nghiệp, tổ chức để tránh các trách nhiệm pháp lý của doanh nghiệp, tổ chức xảy ra (nếu có).')}
            </div>
          </div>
        </div>
      </div>
      )}

      {id === '4' && (
      <div className={styles.ContentContainer}>
        <div>
          <Service4 />
        </div>

        <div className={styles.TextContainer}>
          <div className={styles.Title}>{t('TƯ VẤN VỀ TECHNICAL & BLOCKCHAIN & CRYPTO')}</div>

          <div>
            {t('Hiện nay, công nghệ blockchain đang phát triển nhanh chóng và ngày càng được áp dụng trong nhiều lĩnh vực, đặc biệt là các dự án liên quan đến phát triển phần mềm, crypto, game NFT,… Để tăng tính hợp pháp, minh bạch và chuyên nghiệp đặc biệt là tính pháp lý cho các dự án Blockchain trong mắt các nhà đầu tư và khách hàng, các doanh nghiệp sở hữu dự án Blockchain cần phải có một đơn vị chuyên tư vấn, hỗ trợ, đồng hành, cung cấp dịch vụ tư vấn pháp lý trọn vẹn, giải quyết mọi vấn đề pháp lý cho dự án.')}
          </div>

          <div className={styles.Item}>
            {t('Vai trò của tư vấn pháp lý trong lĩnh vực Blockchain & Crypto:')}
          </div>

          <div>
            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Tư vấn pháp lý được đưa ra bởi những chuyên gia/luật sư am hiểu về pháp luật nói chung và Blockchain & Crypto nói riêng.')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Dự án Blockchain được tham vấn ý kiến của chuyên gia sẽ được kinh doanh một cách hợp pháp, an toàn, biết và phòng tránh những rủi ro liên quan đến quá trình hoạt động, cơ cấu hoạt động, bộ máy hoạt đồng, tài chính và nhân sự dự án.')}
            </div>
          </div>

          <div className={styles.Item}>
            {t('Dịch vụ của FLF&TCHNLS:')}
          </div>

          <div>
            {t('FLF&TCHNLS là chuyên gia trong lĩnh vực tư vấn pháp lý về Blockchain & Crypto trên phạm vi trong và ngoài nước, sẽ giúp Doanh nghiệp thực hiện các vấn đề sau:')}
          </div>

          <div>
            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>

              <div>
                {t('Hiện nay tại Việt Nam chưa có khung pháp lý cho lĩnh vực blockchain nên FLF&TCHNLS sẽ tập trung hỗ trợ và phân tích cho khách hàng các vấn đề:')}

                <ul>
                  <li>
                    {t('Biết được những đầu mục công việc có thể làm và không thể làm tại Việt Nam.')}
                  </li>

                  <li>
                    {t('Trong trường hợp không thể thực hiện tại Việt Nam thì có thể làm ở đâu.')}
                  </li>

                  <li>
                    {t('Quy trình thực hiện như thế nào và cần phải có những hồ sơ, hợp đồng như thế nào để thực hiện.')}
                  </li>
                </ul>
              </div>

            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Tư vấn các vấn đề liên quan đến đổi hàng hoá trong lĩnh vực blockchain.')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Tư vấn chiến lược cho dự án blockchain từ khi được thành lập cho đến khi hoạt động ổn định, lâu dài và phát triển bền vững.')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('FLF&TCHNLS hỗ trợ thành lập công ty về Blockchain, crypto tại các quốc gia được phép hoạt động như Singapore, Mỹ, BVI,…')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('FLF&TCHNLS hỗ trợ khách hàng đăng ký các loại giấy phép kinh doanh, giấy phép lao động, cho thuê địa điểm văn phòng, nhận văn thư,các hoạt động về quản lý, điều hành khác tại Singapore, Mỹ, BVI,...')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>

              <div>
                {t('Phát hành Thư tư vấn pháp lý liên quan đến Dự án blockchain như:')}

                <ul>
                  <li>
                    {t('Tiến hành phân tích toàn bộ tài liệu của dự án từ đó đánh giá dự án trên góc độ pháp lý để tiến hành những điều chỉnh thích hợp.')}
                  </li>

                  <li>
                    {t('Xác định những rủi ro đang tồn tại hay tìm ẩn trong dự án để đề ra phương án giải quyết và lập kế hoạch dự phòng rủi ro toàn diện cho dự án.')}
                  </li>

                  <li>
                    {t('Tư vấn mọi vấn đề về pháp lý cho dự án/doanh nghiệp sở hữu dự án.')}
                  </li>

                  <li>
                    {t('Việc tư vấn pháp lý do các chuyên gia/luật sư có nhiều kinh nghiệm trực tiếp thực hiện.')}
                  </li>
                </ul>
              </div>
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Soạn thảo, xây dựng và đăng ký bản quyền tác giả cho dự án blockchain tại Việt Nam.')}
            </div>
          </div>
        </div>
      </div>
      )}

      {id === '5' && (
      <div className={styles.ContentContainer}>
        <div>
          <Service4 />
        </div>

        <div className={styles.TextContainer}>
          <div className={styles.Title}>{t('TƯ VẤN MUA BÁN VÀ SÁP NHẬP DOANH NGHIỆP')}</div>

          <div>
            {t('Dịch vụ của FLF&TCHNLS chủ yếu đối với doanh nghiệp Fintech và SME:')}
          </div>

          <div className={styles.Item}>
            {t('Giai đoạn trước khi mua bán, sáp nhập.')}
          </div>

          <div>
            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Hỗ trợ tìm kiếm doanh nghiệp đối tượng phù hợp với yêu cầu của khách hàng trong trường hợp khách hàng chưa tìm được đối tượng.')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Tư vấn điều kiện, quy trình, quy định chung của pháp luật về việc mua bán, sáp nhập, chia tách doanh nghiệp.')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Đánh giá, phân tích doanh nghiệp đối tượng về pháp lý, tài sản, tài chính (tuỳ thuộc vào vai trò của khách hàng là bên mua hay bên bán đề đưa ra bộ tiêu chí đánh giá phù hợp).')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Lập báo cáo phân tích điểm mạnh, điểm yếu dựa trên hồ sơ pháp lý và phân tích doanh nghiệp đối tượng.')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Đưa ra ý kiến chuyên môn pháp lý cho trường hợp cụ thể của khách hàng để thống nhất phương án mua bán, sáp nhập.')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Tư vấn các vấn đề liên quan đến giá trị doanh nghiệp, giá mua, giá bán và các loại thuế, phí của nhà nước.')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Tư vấn các yêu cầu pháp lý khác của khách hàng.')}
            </div>
          </div>

          <div className={styles.Item}>
            {t('Giai đoạn thực hiện thủ tục mua bán, sáp nhập doanh nghiệp.')}
          </div>

          <div>
            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Sau khi đã thống nhất được phương án mua bán, sáp nhập doanh nghiệp tại giai đoạn 1, FLF&TCHNLS sẽ tiến hành soạn thảo toàn bộ hồ sơ, văn bản theo quy định.')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('FLF&TCHNLS sẽ đại diện khách hàng liên hệ và trực tiếp làm việc với các Sở, ban ngành để thực hiện thủ tục mua bán, sáp nhập đến khi ra Giấy phép mới.')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('FLF&TCHNLS sẽ đại diện khách hàng liên hệ với cơ quan quản lý thuế của doanh nghiệp để thực hiện việc khai thuế và đóng thuế theo quy định (nếu có).')}
            </div>
          </div>

          <div className={styles.Item}>
            {t('Giai đoạn sau khi thực hiện thủ tục mua bán, sáp nhập doanh nghiệp.')}
          </div>

          <div>
            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('FLF&TCHNLS sẽ tư vấn giải pháp để doanh nghiệp mới bị mua bán, sáp nhập doanh nghiệp hoạt động ổn định, phát triển bền vững.')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('FLF&TCHNLS hỗ trợ tư vấn thường xuyên theo tháng cho doanh nghiệp (nếu doanh nghiệp có nhu cầu thực hiện).')}
            </div>
          </div>
        </div>
      </div>
      )}

      {id === '6' && (
      <div className={styles.ContentContainer}>
        <div>
          <Service4 />
        </div>

        <div className={styles.TextContainer}>
          <div className={styles.Title}>{t('THỰC HIỆN THỦ TỤC GIẤY PHÉP KINH DOANH')}</div>

          <div>
            {t('FLF&TCHNLS có đội ngũ Luật sư, chuyên viên tư vấn có trình độ chuyên môn cao và nhiều năm kinh nghiệm thực hiện các thủ tục hành chính, xin cấp phép kinh doanh cho các doanh nghiệp Việt Nam, doanh nghiệp có vốn đầu tư nước ngoài, nhà đầu tư nước ngoài là cá nhân. Chính vì thế FLF&TCHNLS tự tin rằng có thể cung cấp các dịch vụ tư vấn và thực hiện các thủ tục xin cấp phép từ cơ bản đến chuyên sâu cho cả khách hàng là doanh nghiệp và khách hàng là cá nhân.')}
          </div>

          <div className={styles.Item}>
            {t('FLF&TCHNLS cung cấp các dịch vụ xin cấp Giấy phép kinh doanh như sau:')}
          </div>

          <div>
            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Thủ tục thành lập doanh nghiệp;')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Thủ tục xin Giấy phép đầu tư;')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Thủ tục giải thể/tái cơ cấu doanh nghiệp;')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Bảo hộ thương hiệu/nhãn hiệu;')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Giấy chứng nhận vệ sinh an toàn thực phẩm;')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Giấy phép Thành lập trung tâm ngoại ngữ;')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Giấy phép Hoạt động trung tâm ngoại ngữ;')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Hồ sơ Phòng cháy chữa cháy;')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Giấy phép lao động;')}
            </div>

            <div className={styles.TickContainer}>
              <div>
                <Tick />
              </div>
              {t('Thủ tục về công bố lưu hành;')}
            </div>
          </div>
        </div>
      </div>
      )}

      <RelatedCases />

      <OtherServies />
    </div>
  );
}

export default ServiceDetailsPage;
