import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import LOCATIONS from 'constants';

import { AnimatePresence, motion } from 'framer-motion';

import { ReactComponent as Logo } from 'assets/icons/Logo.svg';
import { ReactComponent as Ellipse } from 'assets/icons/Ellipse1.svg';
// import { ReactComponent as ArrowToRight2 } from 'assets/icons/ArrowToRight2.svg';
// import { ReactComponent as ShieldLowerOpacity } from 'assets/images/ShieldLowerOpacity.svg';
// import { ReactComponent as TextShield } from 'assets/images/TextShield.svg';
import { ReactComponent as LogoWithName } from 'assets/icons/LogoWithName.svg';

import AboutTop from './AboutTop/AboutTop';
import HomeTop from './HomeTop/HomeTop';
import ServicesTop from './ServicesTop/ServicesTop';
import CaseStudyTop from './CaseStudyTop/CaseStudyTop';
import ContactTop from './ContactTop/ContactTop';
import BlogTop from './BlogTop/BlogTop';
import NewTop from './NewTop/NewTop';
import CaseStudyDetailTop from './CaseStudyDetailTop/CaseStudyDetailTop';
import ServicesDetailTop from './ServicesDetailTop/ServicesDetailTop';
import LanguageSwitcher from 'utils/LanguageSwitcher';

import WithAnimation from './WithAnimationHOC/WithAnimation';

import styles from './index.module.scss';

const fadeMotion = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

const shieldAboutMotion = {
  initial: { top: '-100px', left: 'calc(100% - 400px)', opacity: 0 },
  animate: { top: '-100px', left: 'calc(100% - 400px)', opacity: 1 },
  exit: { top: '265px', left: '30%', opacity: 0 },
};

function Header({ changeLanguage, selectedLanguage }) {
  const location = useLocation();
  const { t } = useTranslation();

  const [showDropdown, setShowDropdown] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [containerHeight, setContainerHeight] = useState('140px');
  const [isShowMenu, setIsShowMenu] = useState(false);
  const dropdownRef = useRef(null);

  const onCloseMenu = useCallback(() => {
    setIsShowMenu(false);
  }, []);

  const onOpenMenu = useCallback(() => {
    setIsShowMenu(true);
  }, []);

  const handleAnimation = useCallback((routeFrom, routeTo) => () => {
    const routeToWithSlash = `/${routeTo}`;

    if (routeFrom === '/' || routeToWithSlash === '/') {
      shieldAboutMotion.initial = { top: '265px', left: '30%', opacity: 0 };
      shieldAboutMotion.animate = { top: '-100px', left: 'calc(100% - 400px)', opacity: 1 };
      shieldAboutMotion.exit = { top: '265px', left: '30%', opacity: 0 };
    } else if (routeFrom === '/contact' || routeToWithSlash === '/contact') {
      shieldAboutMotion.initial = { top: '-100px', left: 'calc(100% - 400px)', opacity: 0 };
      shieldAboutMotion.animate = { top: '-100px', left: 'calc(100% - 400px)', opacity: 1 };
      shieldAboutMotion.exit = { top: '-100px', left: 'calc(100% - 400px)', opacity: 0 };
    } else {
      shieldAboutMotion.initial = { top: '-100px', left: 'calc(100% - 400px)' };
      shieldAboutMotion.animate = null;
      shieldAboutMotion.exit = null;
    }
  }, [shieldAboutMotion]);

  const isCurrentRoute = (route) => location.pathname === (`/${route}`);

  const isContainedRoute = (route) => location.pathname.includes(route);

  const isInRoute = useCallback(() => {
    if (
      location.pathname === `/${LOCATIONS.ABOUT}`
      || location.pathname === `/${LOCATIONS.SERVICES}`
      || location.pathname === `/${LOCATIONS.CASESTUDY}`
      || location.pathname === `/${LOCATIONS.BLOG}`
      || location.pathname === `/${LOCATIONS.NEW}`
    ) {
      return true;
    }
    return false;
  }, [location.pathname]);

  const updateInnerContainerHeight = useCallback(() => {
    const { innerWidth } = window;

    if (location.pathname === LOCATIONS.HOME) {
      if (innerWidth <= 1100 && innerWidth > 768) {
        setContainerHeight('520px');
      } else if (innerWidth <= 768) {
        setContainerHeight('400px');
      } else {
        setContainerHeight('761px');
      }
    } else if (location.pathname === `/${LOCATIONS.ABOUT}`) {
      setContainerHeight(innerWidth <= 768 ? '810px' : '890px');
    } else if (location.pathname === `/${LOCATIONS.SERVICES}`) {
      setContainerHeight(innerWidth <= 768 ? '190px' : '352px');
    } else if (location.pathname === `/${LOCATIONS.CASESTUDY}`) {
      setContainerHeight(innerWidth <= 768 ? '190px' : '352px');
    } else if (location.pathname === `/${LOCATIONS.CONTACT}`) {
      setContainerHeight('600px');
    } else if (location.pathname === `/${LOCATIONS.BLOG}`) {
      setContainerHeight(innerWidth <= 768 ? '190px' : '352px');
    } else if (location.pathname === `/${LOCATIONS.NEW}`) {
      setContainerHeight(innerWidth <= 768 ? '190px' : '352px');
    } else if (location.pathname.includes(`/${LOCATIONS.CASESTUDY}/`)) {
      setContainerHeight(innerWidth <= 768 ? '190px' : '352px');
    } else if (location.pathname.includes(`/${LOCATIONS.SERVICES}/`)) {
      setContainerHeight(innerWidth <= 768 ? '190px' : '352px');
    } else {
      setContainerHeight(innerWidth <= 1100 ? '80px' : '140px');
    }
  }, [location.pathname]);

  useEffect(() => {
    const handleMouseEnter = () => {
      setShowDropdown(true);
    };

    const handleMouseLeave = () => {
      setShowDropdown(false);
    };

    const dropdownItem = dropdownRef.current;

    dropdownItem.addEventListener('mouseenter', handleMouseEnter);
    dropdownItem.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      dropdownItem.removeEventListener('mouseenter', handleMouseEnter);
      dropdownItem.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  useEffect(() => {
    updateInnerContainerHeight();
    setIsShowMenu(false);
  }, [location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;
      const threshold = parseInt(containerHeight, 10);

      setIsSticky(scrollPosition >= threshold);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [containerHeight]);

  useEffect(() => {
    const handleResize = () => {
      updateInnerContainerHeight();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [updateInnerContainerHeight]);

  return (
    <div className={`${isContainedRoute(LOCATIONS.NEW) && styles.OverflowChange} ${isContainedRoute(LOCATIONS.BLOG) && styles.OverflowChange} ${isContainedRoute(LOCATIONS.ABOUT) && styles.OverflowChange} ${styles.HeaderContainer}`} style={{ height: containerHeight }}>
      <header className={`${isSticky ? styles.Sticky : ''}`}>
        <div className={styles.Logo}>
          <Link
            to={LOCATIONS.HOME}
            onClick={handleAnimation(location.pathname, '')}
          >
            <Logo />
          </Link>
        </div>

        <button
          type="button"
          className={`${styles.MobileMenuIcon} ${isShowMenu ? styles.Open : ''}`}
          onClick={onOpenMenu}
        >
          <div className={styles.Bar} />
          <div className={styles.Bar} />
          <div className={styles.Bar} />
        </button>

        <nav className={styles.NavWrapper}>
          <ul>
            <li className={isCurrentRoute(LOCATIONS.ABOUT) ? styles.Active : ''}>
              <Link
                to={LOCATIONS.ABOUT}
                onClick={handleAnimation(location.pathname, LOCATIONS.ABOUT)}
              >
                {t('Giới thiệu')}
              </Link>

              <Ellipse className={styles.Ellipse} />
            </li>

            <li className={isContainedRoute(LOCATIONS.SERVICES) ? styles.Active : ''}>
              <Link
                to={LOCATIONS.SERVICES}
                onClick={handleAnimation(location.pathname, LOCATIONS.SERVICES)}
              >
                {t('Dịch vụ')}
              </Link>

              <Ellipse className={styles.Ellipse} />
            </li>

            <li className={isContainedRoute(LOCATIONS.CASESTUDY) ? styles.Active : ''}>
              <Link
                to={LOCATIONS.CASESTUDY}
                onClick={handleAnimation(location.pathname, LOCATIONS.CASESTUDY)}
              >
                {t('Nghiên cứu')}
              </Link>

              <Ellipse className={styles.Ellipse} />
            </li>

            <li
              className={`${styles.Dropdown} ${showDropdown ? styles.ShowDropdown : ''} ${isContainedRoute(LOCATIONS.BLOG) ? styles.Active : ''}`}
              ref={dropdownRef}
            >
              <Link
                to={LOCATIONS.BLOG}
                onClick={handleAnimation(location.pathname, LOCATIONS.BLOG)}
              >
                {t('Blog')}
              </Link>

              <Ellipse className={styles.Ellipse} />
            </li>

            <li className={isCurrentRoute(LOCATIONS.CONTACT) ? styles.Active : ''}>
              <Link
                to={LOCATIONS.CONTACT}
                onClick={handleAnimation(location.pathname, LOCATIONS.CONTACT)}
              >
                {t('Liên Hệ')}
              </Link>

              <Ellipse className={styles.Ellipse} />
            </li>

            <LanguageSwitcher
              onChangeLanguage={changeLanguage}
              selectedLanguage={selectedLanguage}
            />
          </ul>
        </nav>

        <nav className={`${styles.MobileWrapper} ${isShowMenu ? styles.MobileNav : ''}`}>
          <div
            className={styles.Overlay}
            onKeyDown={() => null}
            tabIndex={0}
            role="button"
            onClick={onCloseMenu}
            aria-label="Close Menu"
          />

          <div className={styles.Menu}>
            <Link
              to={LOCATIONS.HOME}
              onClick={handleAnimation(location.pathname, '')}
            >
              <LogoWithName />
            </Link>

            <ul>
              <li className={isCurrentRoute(LOCATIONS.ABOUT) ? styles.Active : ''}>
                <Link
                  to={LOCATIONS.ABOUT}
                  onClick={handleAnimation(location.pathname, LOCATIONS.ABOUT)}
                >
                  {t('Giới thiệu')}

                  <Ellipse className={styles.Ellipse} />
                </Link>
              </li>

              <li className={isContainedRoute(LOCATIONS.SERVICES) ? styles.Active : ''}>
                <Link
                  to={LOCATIONS.SERVICES}
                  onClick={handleAnimation(location.pathname, LOCATIONS.SERVICES)}
                >
                  {t('Dịch vụ')}

                  <Ellipse className={styles.Ellipse} />
                </Link>
              </li>

              <li className={isContainedRoute(LOCATIONS.CASESTUDY) ? styles.Active : ''}>
                <Link
                  to={LOCATIONS.CASESTUDY}
                  onClick={handleAnimation(location.pathname, LOCATIONS.CASESTUDY)}
                >
                  {t('Nghiên cứu')}

                  <Ellipse className={styles.Ellipse} />
                </Link>
              </li>

              <li className={isCurrentRoute(LOCATIONS.BLOG) ? styles.Active : ''}>
                <Link
                  to={LOCATIONS.BLOG}
                  onClick={handleAnimation(location.pathname, LOCATIONS.BLOG)}
                >
                  {t('Blog')}

                  <Ellipse className={styles.Ellipse} />
                </Link>
              </li>

              <li className={isCurrentRoute(LOCATIONS.CONTACT) ? styles.Active : ''}>
                <Link
                  to={LOCATIONS.CONTACT}
                  onClick={handleAnimation(location.pathname, LOCATIONS.CONTACT)}
                >
                  {t('Liên Hệ')}

                  <Ellipse className={styles.Ellipse} />
                </Link>
              </li>

              <LanguageSwitcher
                onChangeLanguage={changeLanguage}
                selectedLanguage={selectedLanguage}
              />
            </ul>
          </div>
        </nav>
      </header>

      <div className={styles.Container}>
        <AnimatePresence>
          {isCurrentRoute('') && WithAnimation(HomeTop, 'hometop', fadeMotion)}

          {isCurrentRoute(LOCATIONS.ABOUT) && WithAnimation(AboutTop, 'aboutcontainer', fadeMotion)}

          {isCurrentRoute(LOCATIONS.SERVICES) && WithAnimation(ServicesTop, 'services', fadeMotion)}

          {isContainedRoute(`${LOCATIONS.SERVICES}/`)
            && WithAnimation(ServicesDetailTop, 'casestudy', fadeMotion)}

          {isCurrentRoute(LOCATIONS.CASESTUDY) && WithAnimation(CaseStudyTop, 'casestudy', fadeMotion)}

          {isContainedRoute(`${LOCATIONS.CASESTUDY}/`)
            && WithAnimation(CaseStudyDetailTop, 'casestudy', fadeMotion)}

          {isCurrentRoute(LOCATIONS.BLOG) && WithAnimation(BlogTop, 'blog', fadeMotion)}

          {isCurrentRoute(LOCATIONS.NEW) && WithAnimation(NewTop, 'new', fadeMotion)}

          {isCurrentRoute(LOCATIONS.CONTACT) && WithAnimation(ContactTop, 'contact', fadeMotion)}

          {isInRoute() && (
            <motion.div
              className={styles.ShieldLowerOpacity}
              key="shieldlower"
              initial="initial"
              animate="animate"
              exit="exit"
              variants={shieldAboutMotion}
              transition={{ duration: 0.5 }}
            >
              <div className={styles.ShieldContainer}>
                {/* <ShieldLowerOpacity /> */}
                {/* <TextShield className={styles.TextShield} /> */}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

Header.propTypes = {
  changeLanguage: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
};

export default Header;
