import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Ellipse } from 'assets/icons/Ellipse.svg';
import { ReactComponent as ArrowToRight1 } from 'assets/icons/ArrowToRight1.svg';
// import { ReactComponent as Shield } from 'assets/icons/Shield.svg';
import { ReactComponent as ServicesDeco } from 'assets/images/ServicesDeco.svg';

import ScheduleContact from 'components/ScheduleContact/ScheduleContact';

import ServicesBanner from 'assets/images/ServicesBanner.png';

import styles from './index.module.scss';

function ServicesPage() {
  const { t } = useTranslation();
  return (
    <div>
      <div className={styles.InfoContainer}>
        <div className={styles.TextContainer}>
          <br />
          <br />

          <div>
            {t('First Legal Force (FLF) là tổ chức tiên phong hoạt động theo mô hình liên kết chặt chẽ với nhiều đơn vị hành nghề Luật sư chuyên nghiệp, song song với việc tập hợp các tổ chức chuyên gia về phát triển và quản trị kinh doanh trong cả nước. FLF ra đời nhằm cung cấp giải pháp, chia sẻ các gánh nặng và đồng hành cùng doanh nghiệp giúp họ “Phòng tránh - Giảm thiểu - Chuyển giao” các rủi ro pháp lý hiện tại hoặc tiềm ẩn trong suốt quá trình hoạt động kinh doanh của doanh nghiệp.')}
            <br />
            <br />
            {t('FLF & TCHNLS  là một tổ hợp tư vấn đa lĩnh vực bao gồm: pháp lý, quản trị nhân sự, tài chính… tại Việt nam. FLF & TCHNLS tự hào có nhiều năm kinh nghiệm tư vấn cho các doanh nghiệp Việt Nam, doanh nghiệp có vốn đầu tư nước ngoài và nhà đầu tư nước ngoài là cá nhân. Chính vì thế FLF & TCHNLS tự tin rằng có thể cung cấp các dịch vụ tư vấn và thực hiện các thủ tục xin cấp phép  từ cơ bản đến chuyên sâu cho cả khách hàng là doanh nghiệp và khách hàng là cá nhân.')}
            <br />
            <br />
            <div className={styles.BottomText}>
              {t('Nếu bạn đang gặp vướng mắc trong quá trình kinh doanh, hãy liên hệ ngay với FLF & TCHNLS để được hỗ trợ tốt nhất.')}
            </div>
          </div>
        </div>

        <div className={styles.ImageContainer}>
          <img src={ServicesBanner} alt="Services" />
        </div>
      </div>

      <div className={styles.Container}>
        <div>
          <div className={styles.Subtitle}>{t('Tất cả các dịch vụ')}</div>

          <div className={styles.List}>
            <div className={`${styles.Active} ${styles.Item}`}>
              <Link to="/services/1">
                <span>01</span>
                <div>{t('Thành lập doanh nghiệp 100% vốn nước ngoài')}</div>
                <ArrowToRight1 className={styles.ArrowToRight} />
              </Link>

              <div>
                {/* <Shield /> */}
              </div>

              <div className={styles.Divider} />
            </div>

            <div className={styles.Item}>
              <Link to="/services/2">
                <span>02</span>
                <div>{t('Đăng ký quyền tác giả')}</div>
              </Link>

              <div>
                <Ellipse />
              </div>

              <div className={styles.Divider} />
            </div>

            <div className={styles.Item}>
              <Link to="/services/3">
                <span>03</span>
                <div>{t('Cấp giấy phép lao động cho người nước ngoài')}</div>
              </Link>

              <div>
                <Ellipse />
              </div>

              <div className={styles.Divider} />
            </div>

            <div className={styles.Item}>
              <Link to="/services/4">
                <span>04</span>
                <div>{t('Tư vấn về Technical & Blockchain & Crypto')}</div>
              </Link>

              <div>
                <Ellipse />
              </div>

              <div className={styles.Divider} />
            </div>

            <div className={styles.Item}>
              <Link to="/services/5">
                <span>05</span>
                <div>{t('Tư vấn mua bán và sáp nhập doanh nghiệp')}</div>
              </Link>

              <div>
                <Ellipse />
              </div>

              <div className={styles.Divider} />
            </div>

            <div className={styles.Item}>
              <Link to="/services/6">
                <span>06</span>
                <div>{t('Thực hiện thủ tục giấy phép kinh doanh')}</div>
              </Link>

              <div>
                <Ellipse />
              </div>

              <div className={styles.Divider} />
            </div>
          </div>
        </div>

        <ServicesDeco className={styles.ServicesDeco} />
      </div>

      <ScheduleContact />
    </div>
  );
}

export default ServicesPage;
