import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { actionGetAllCases } from 'store/actions';

import ScheduleContact from 'components/ScheduleContact/ScheduleContact';

import CaseCard from 'components/CaseCard/CaseCard';
import CaseCard1 from 'components/CaseCard1/CaseCard1';

import styles from './index.module.scss';

function CaseStudyPage() {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.Cases);

  const selectedLanguage = localStorage.getItem('selectedLanguage');

  useEffect(() => {
    dispatch(actionGetAllCases());
  }, [selectedLanguage]);

  return (
    <div>
      <div className={styles.CardsContainer}>
        <div className={styles.InnerContainer}>
          {data.cases.map((c, index) => (
            <Link to={`/casestudy/${c.slug}`} key={c.slug}>
              {index === 0 ? (
                <CaseCard imgUrl={c.coverImage.url} description={c.title} />
              ) : (
                <CaseCard1 imgUrl={c.coverImage.url} description={c.title} />
              )}
            </Link>
          ))}
        </div>
      </div>

      <ScheduleContact />
    </div>
  );
}

export default CaseStudyPage;
