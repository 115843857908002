import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { motion } from 'framer-motion';

import { ReactComponent as AboutDeco } from 'assets/images/AboutDeco.svg';
import { ReactComponent as ArrowToRight } from 'assets/icons/ArrowToRight.svg';

import styles from './index.module.scss';

const contentFadeMotion = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

function AboutTop() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleToContact = () => {
    navigate('/contact');
  };

  return (
    <motion.div
      className={styles.AboutContainer}
      key="aboutcontainer"
      initial="initial"
      animate="animate"
      exit="exit"
      variants={contentFadeMotion}
      transition={{ duration: 0.5 }}
    >
      <div className={styles.InnerContainer}>
        <div>
          <div className={styles.TitleContainer}>
            <div className={styles.Title}>First Legal Force</div>

            <div className={styles.Subtitle2}>
              FIRST LEGAL FORCE
              <br />
              {t('chuyên cung cấp các giải pháp giúp quản trị rủi ro, thực hiện các thủ tục hành chính cho doanh nghiệp')}
            </div>
          </div>

          <div className={styles.InfoContainer}>
            <div className={styles.InfoLeftContainer}>
              <div className={styles.InfoItem}>
                <div className={styles.InfoNum}>40+</div>

                <div className={styles.InfoText}>
                  {t('Case tư vấn về TECHNICAL & BLOCKCHAIN & CRYPTO')}
                </div>
              </div>

              <div className={styles.InfoItem}>
                <div className={styles.InfoNum}>50+</div>

                <div className={styles.InfoText}>
                  {t('Giấy phép đầu tư cho người nước ngoài')}
                </div>
              </div>

              <div className={styles.InfoItem}>
                <div className={styles.InfoNum}>25+</div>

                <div className={styles.InfoText}>
                  {t('Case đăng ký quyền tác giả về BLOCKCHAIN & CRYPTO')}
                </div>
              </div>
            </div>

            <button type="button" className={styles.ToContactButton} onClick={handleToContact}>
              {t('LIÊN HỆ')}

              <ArrowToRight />
            </button>
          </div>
        </div>

        <AboutDeco className={styles.AboutDeco} />
      </div>
    </motion.div>
  );
}

export default AboutTop;
