import React from 'react';

import OurTeam from 'assets/images/OurTeam.png';

// import { ReactComponent as MemberBackground } from 'assets/images/MemberBackground.svg';

// import InfoMember from './InfoMember/InfoMember';

import styles from './index.module.scss';

function TeamMember() {
  return (
    <div className={styles.Container}>
      <img src={OurTeam} alt="ourteam" />
    </div>
  );
}

export default TeamMember;
