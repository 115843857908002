import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// import { v4 as uuidv4 } from 'uuid';

import { actionGetCaseBySlug } from 'store/actions';

// import { ReactComponent as Tick } from 'assets/icons/Tick.svg';
// import { ReactComponent as CaseDetailShield } from 'assets/images/CaseDetailShield.svg';
// import { ReactComponent as Shield1 } from 'assets/images/Shield1.svg';

import RelatedCases from 'components/RelatedCases/RelatedCases';

import styles from './index.module.scss';

function CaseDetailPage() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const selectedLanguage = localStorage.getItem('selectedLanguage');

  const data = useSelector((state) => state.Case);

  const parsedHTML = new DOMParser().parseFromString(data.case?.content?.html, 'text/html');

  useEffect(() => {
    dispatch(actionGetCaseBySlug({ slug: id }));
  }, [selectedLanguage]);

  return (
    <div className={styles.Container}>
      <div className={styles.ContentContainer}>
        <div className={styles.TextContainer}>
          <div className={styles.TopContainer}>
            <div className={styles.ItemContainer}>
              <div className={styles.Title}>
                {data.case?.title}
              </div>

              <div dangerouslySetInnerHTML={{ __html: parsedHTML.documentElement.outerHTML }} />
            </div>
          </div>
        </div>
      </div>

      <RelatedCases tags={data.case?.tags} />
    </div>
  );
}

export default CaseDetailPage;
