import React from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import { TextReduceHelper } from 'utils';

import { ReactComponent as ArrowToRight } from 'assets/icons/ArrowToRight1.svg';

import styles from './index.module.scss';

function Card({
  image, title, description, timeToRead,
}) {
  const { t } = useTranslation();
  return (
    <div className={styles.CardContainer}>
      <img src={image} alt={description} />

      <div className={styles.InfoCardContainer}>
        <div className={styles.Title}>{TextReduceHelper({ text: title, maxLength: 65 })}</div>

        <div className={styles.Description}>
          {TextReduceHelper({ text: description, maxLength: 75 })}
        </div>

        <div className={styles.TimeToRead}>
          {timeToRead}
          {' '}
          {t('phút đọc')}
          <ArrowToRight />
        </div>
      </div>
    </div>
  );
}

Card.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  timeToRead: PropTypes.number.isRequired,
};

export default Card;
