import React from 'react';
import PropTypes from 'prop-types';

import styles from './LanguageSwitcher.module.scss';

function LanguageSwitcher({ onChangeLanguage, selectedLanguage }) {
  const handleLanguageChange = (language) => {
    onChangeLanguage(language);
  };

  return (
    <div className={styles.languageSwitch}>
      <select
        value={selectedLanguage}
        onChange={(e) => handleLanguageChange(e.target.value)}
        className={styles.select}
      >
        <option value="en">En</option>
        <option value="vi">Vi</option>
      </select>
    </div>
  );
}

LanguageSwitcher.propTypes = {
  onChangeLanguage: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
};

export default LanguageSwitcher;
